import React, { useEffect } from 'react';
import { Button, Layout, Typography, Anchor } from 'antd';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from './components/Home'
import Privacy from './components/Privacy'
import './App.css';

const { Link: ALink } = Anchor;
const { Text } = Typography
const { Footer, Content } = Layout;

const App = () => {

  useEffect(() => {
    document.title = "ClockWrite.gr"
  }, []);

  return (
    <Layout>
      <Router>
        <Content>
          <Switch>
            <Route path="/privacy">
              <Privacy />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Content>
        <Footer style={{maxHeight: 80}}>
          <Link to={`/privacy`}><Button type="link">Privacy Policy</Button></Link>
          <Text style={{float: 'right', lineHeight: '31px'}}>
            <span style={{float: 'left', fontWeight: 'bold'}}>Contact: </span>
            <Anchor style={{ float:'left', background: 'transparent'}} affix={false}>
              <ALink href="mailto:support@transcontainer.gr" target="blank" title="support@transcontainer.gr" />
            </Anchor>
          </Text>
          
        </Footer>
      </Router>
    </Layout>
  );
}

export default App;
